<template>
  <div class="errors-not-authorized">
    <div class="errors-not-authorized__title">
      {{ $t("errors.notAuthorized.title") }}
    </div>
    <div class="errors-not-authorized__text px-10">
      <span>
        {{ $t("errors.notAuthorized.text") }}
      </span>
    </div>

    <div class="text--center">
      <va-button @click="logout">
        {{ $t("errors.notAuthorized.logout_button") }}
      </va-button>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue'

const auth = inject('auth')

async function logout() {
  await auth.logout()
}
</script>

<style lang="scss">
.errors-not-authorized {
  &__title {
    font-size: 3rem;
    line-height: 1.25em;
    font-weight: 500;
  }

  &__text {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }
}
</style>
